/* double Noun display */
.nounsWrapper {
    border: 1px solid #000000;
    margin:  0.5em 0 0.75em 0;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

}

.imgWrapper{
    z-index: 0;
    position: relative;
}

.noun-1 {
    border-bottom:  1px solid #000;
}


.img {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    width: 100%;
    height: 100%;
    max-width: 200px;
}

.noun-1 .img {
    border-radius: 20px 20px 0 0;
}

.noun-2 .img {
    border-radius: 0 0 20px 20px;
}

/* single Noun display */
.nounWrapper .nounId {
    display: none;
}

.nounId {
    opacity: 0.75;
    background-color: #fff;
    color: #222;
    text-align: center;
    font-size:  1.25em;
    font-family: 'Londrina Solid';
}

.noun-2 .nounId {
    border-radius: 0 0 20px 20px;
}

@media only screen and (min-width: 768px) {
    .nounsWrapper {
        display: flex;
        margin: 1.5em 0;
    }

    .imgWrapper{
        position: relative;
    }

    .noun-1 {
        border-bottom:  0;
        border-right:  1px solid #000;
    }

    .img {
        max-width: 500px;
    }

    /* single Noun display */
    .nounWrapper .img {
        height: 100%;
    }

    .nounWrapper .noun-1 .img {
        border-radius: 20px 0 0 0;
    }

    .nounWrapper .noun-2 .img {
        border-radius: 0 20px 0 0;
    }

    /* double Noun display */
    .nounsWrapper .img {
        height: auto;
    }
    .nounsWrapper .noun-1 .img {
        border-radius: 20px 0 0 20px;
        border-radius: 20px 0 0 0;
    }

    .nounsWrapper .noun-2 .img {
        border-radius: 0 20px 20px 0;
        border-radius: 0 20px 0 0;
    }

    .nounId {
        font-size: 2em;
    }

    .noun-1 .nounId {
        border-radius: 0 0 0 20px;
    }

    .noun-2 .nounId {
        border-radius: 0 0 20px 0;
    }

}

@media only screen and (min-width: 992px) { /*1025*/
    .img {
        /*width:  500px;*/
        max-width: 500px;
    }

    .nounId {
        font-size: 2.5em;
    }
}