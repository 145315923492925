.Footer{
    text-align: center;
    font-family: 'Londrina Solid';
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-self: center;
    background: black;
    width: 100%;
    color: white;
}

a {
	color: white;
}
