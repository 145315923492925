/*.VoteBar {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    width: 30%;
    min-height: 80px;
    min-width: 320px;
    padding: .8em;
    border-radius: 20px;
    border: 1px solid #000000;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.VoteBarOverlay {
    background-color: white;
    opacity: 0.5;
}

.reconnect {
    width: 100%;
    margin: auto;
    text-align: center;
    font-family: Roboto Mono;
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
}*/

.mintBar {
  color: white;
}


.VoteBar {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    min-height: 80px;
    /*
    min-width: 320px;
    padding: .8em;
    */
    /*
    border-radius: 20px;
    border: 1px solid #000000;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.25);
    */
    /*margin-bottom: 20px;*/
}

.VoteBarOverlay-x {
    background-color: white;
    opacity: 0.5;
}

.reconnect {
    width: 100%;
    margin: auto;
    text-align: center;
    font-family: Roboto Mono;
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
}

.voteTitle {
  font-family: 'Londrina Solid';
  font-size: 4rem;
  color: white;
  display: none;
  visibility: hidden;
}

.votePriceTitle {
  font-weight: bold;
  text-align: center;
  /*font-family: 'PT Root UI';
  font-size: 18px;
  */
}

.votePriceText {
  font-weight: bold;
  text-align: center;
  /*
  font-family: 'PT Root UI';
  font-size: 32px;
  margin-bottom: 0px !important;
  margin-top: 3px;
  */
}

.explainerText {
  display: none;
  visibility: hidden;
}

@media (max-width: 992px) {
	.VoteBar {
	  justify-content: space-evenly;
	}


	.voteTitle {
	  font-size: 3rem;
	  color: white;
	}

	.votePriceText {
	  text-align: right;
	 }
	 
	 .explainerText {
	 }

	 ol, ul {
	 }
}

@media (max-width: 568px) {
	.voteTitle {
	  font-size: 3rem;
	  color: white;
	}

	.votePriceText {
	  text-align: right;
	 }
	 
	 .explainerText {
	  	font-size: 0.9rem;	 	
	 }

	 ol, ul {
		padding-left: 1rem;	 	
	 }	 
}