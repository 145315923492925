.HeaderBar {
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  background-color: black;
  color: white;
}

.fundsLow {
  color: red;
}

.navBarBrand {
  position: relative;
  /*z-index: 2;*/
  padding: 5px;
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.connectBtn {
  cursor: pointer;
}
.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
  cursor: pointer !important;
}
.disconnectBtn {
  cursor: pointer;
}
.disconnectBtn:hover,
.disconnectBtn:active,
.disconnectBtn:focus {
  cursor: pointer !important;
}

.greenStatusCircle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: 5px;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  font-size: 0.9rem;
  padding: 0.5rem 0.8rem !important;
  color: var(--brand-black) !important;
}
.nounsNavLink:hover {
  color: var(--brand-dark-red) !important;
}

.testnetImg {
  width: auto;
  height: 45px;
}

.addressNavLink {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(234, 54, 40, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-light .navbar-toggler {
  border-color: rgb(234, 54, 40);
} 